export const ShowWelcomePage: { [key: string]: boolean } = {
  'lotusaviator365.club': true,
  'lotus365games.club': true,
  'lotus365games.co': true,
  'lotusgames365.co': true,
  'lotusgames365.club': true,
};
export const WHDetails: {
  [key: string]: {
    whatsapp: string;
    bank: string;
    accType: string;
    accName: string;
    accNum: string;
    ifsc: string;
    gpayNum: string;
    gpayUpi: string;
    phonePayNum: string;
    phonePayUpi: string;
    paytmNum: string;
  };
} = {
  dingo777: {
    whatsapp: '+919328893488',
    bank: 'ICIC Bank',
    accType: 'CURRENT ACCOUNT',
    accName: 'HARIOM JEWELLERS',
    accNum: '030805501063',
    ifsc: 'ICIC0000308',
    gpayNum: '+9195860 03386',
    gpayUpi: 'jalaramjnd1@okaxis',
    phonePayNum: '+919586003386',
    phonePayUpi: 'ronakatm@ybl',
    paytmNum: null,
  },
  wazirexch: {
    whatsapp: '+91**********',
    bank: '***** Bank',
    accType: 'CURRENT ACCOUNT',
    accName: '**** *****',
    accNum: '********',
    ifsc: '*******',
    gpayNum: '+91******',
    gpayUpi: '******@****',
    phonePayNum: '+91********',
    phonePayUpi: '******@***',
    paytmNum: null,
  },
};
